<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-text>
        <filterComponents
          @doFilter="setFilter"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        {{ $t('sales.sales') }}
        <v-spacer />
        <v-btn
          v-if="this.$route.name === 'salesReport'"
          outlined
          class="mx-2"
          color="primary"
          @click="exportExel()"
        >
          {{ $t('actions.print') }}
        </v-btn>
        <router-link
          :to="{ path: '/sales/salesForm'}"
          color="primary"
        >
          <v-btn
            outlined
            class="mx-2"
            color="primary"
          >
            {{ $t('actions.add') }}
          </v-btn>
        </router-link>
      </v-card-title>
      <v-data-table
        :loading="dataLoading"
        :headers="headers"
        :search="search"
        :items="list"
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :options.sync="options"
        :server-items-length="total"
        :page-count="numberOfPages"
        @fetchAllItems="fetchAllItems"
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-img
            width="70"
            height="70"
            :src="'https://api.newturquoisejewelry.com/img/products/'+ item.product.image"
          />
        </template>
        <template v-slot:[`item.remainingAmount`]="{ item }">
          {{ item.receipts.total_egp - item.receipts.paid_egp }}
        </template>
        <template
          v-slot:no-data
          loading
        >
          <p>{{ $t('actions.noData') }}</p>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                outlined
                class="mx-2"
                color="blue"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-tooltip
                    bottom
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-btn
                        small
                        fab
                        outlined
                        class="mx-2"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="openMoreDetails(item)"
                      >
                        <v-icon>
                          fa-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('stock.moreDetails') }}
                  </v-tooltip>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-tooltip
                    bottom
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-btn
                        small
                        fab
                        outlined
                        class="mx-2"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="openinvoice(item)"
                      >
                        <v-icon>
                          fa-file
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('actions.viewInvoice') }}
                  </v-tooltip>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-tooltip
                    bottom
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-btn
                        small
                        fab
                        outlined
                        class="mx-2"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="generateReport(item)"
                      >
                        <v-icon>
                          fa-download
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('actions.print') }}
                  </v-tooltip>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-tooltip
                    bottom
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <router-link
                        :to="{ path: '/sales/return/' + item.product_id}"
                        color="primary"
                      >
                        <v-btn
                          small
                          fab
                          outlined
                          class="mx-2"
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>
                            fa-hand-holding-magic
                          </v-icon>
                        </v-btn>
                      </router-link>
                    </template>
                    {{ $t('actions.return') }}
                  </v-tooltip>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <template>
        <div>
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="فاتورة"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            @progress="onProgressPdf($event)"
          >
            <invoice
              slot="pdf-content"
              :item-details="itemDetails"
            />
          </vue-html2pdf>
          <custom-progress
            v-show="showProgress"
            :progress="progressNumber"
          />
        </div>
      </template>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog
      v-model="detailsDialog"
      width="1200"
    >
      <v-card>
        <v-card-text>
          <moreDetails :item-details="itemDetails.product" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="invoiceDialog"
      width="850"
    >
      <v-card>
        <v-card-text>
          <invoice :item-details="itemDetails" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import moreDetails from '../products/detailsForm.vue'
  import invoice from './invoice.vue'
  import VueHtml2pdf from 'vue-html2pdf'
  import CustomProgress from '../component/progress.vue'
  import filterComponents from './filter.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'
  const salesService = ServiceFactory.get('sales')
  export default {
    name: 'Companies',
    components: {
      VueHtml2pdf,
      CustomProgress,
      filterComponents,
      moreDetails,
      invoice,
    },
    data: (vm) => ({
      search: '',
      dataLoading: false,
      page: 0,
      total: 0,
      numberOfPages: 0,
      path: '',
      options: {},
      list: [],
      Roles: [],
      filter: {
        branch_id: null,
        receipt_number: null,
        from_date: null,
        to_date: null,
        metal_type: null,
        client_id: null,
        employee_id: null,
      },
      loading: false,
      deleteDailog: false,
      itemDetails: {},
      detailsDialog: false,
      invoiceDialog: false,
      editedIndex: -1,
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      disabled: false,
      headers: [
        {
          text: vm.$t('settings.id'),
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: vm.$t('sales.productCode'), sortable: false, value: 'product.label' },
        { text: vm.$t('sales.branch'), sortable: false, value: 'receipts.branch.name' },
        { text: vm.$t('sales.date'), sortable: false, value: 'receipts.receipt_date' },
        { text: vm.$t('sales.metalType'), sortable: false, value: 'product.metal.name' },
        { text: vm.$t('sales.invoiceNumber'), sortable: false, value: 'receipts.receipt_number' },
        { text: vm.$t('sales.sellingPrice'), sortable: false, value: 'receipts.total_egp' },
        { text: vm.$t('sales.paidAmount'), sortable: false, value: 'receipts.paid_egp' },
        { text: vm.$t('sales.remainingAmount'), sortable: false, value: 'remainingAmount' },
        { text: vm.$t('sales.biller'), sortable: false, value: 'receipts.employee.name' },
        { text: vm.$t('sales.image'), sortable: false, value: 'image' },
        { text: vm.$t('actions.actions'), value: 'actions', sortable: false },
      ],
      header: ['Product Code', 'branch', 'date', 'Metal Type', 'Invoice Number', 'Selling Price', 'Selling Price', 'Paid Amount', 'Remaining Amount', 'Biller'],
      bookType: 'xlsx',
      autoWidth: true,
      filename: 'Sales Report',
      progressNumber: 0,
      showProgress: false,
      pdfDataLoading: false,
    }),
    watch: {
      options: {
        handler () {
          this.fetchAllItems()
        },
      },
    },
    methods: {
      openMoreDetails (item) {
        this.itemDetails = {}
        this.detailsDialog = true
        this.itemDetails = item
        this.itemDetails.product.action = 'view'
        this.itemDetails.product.branch = item.receipts.branch
      },
      openinvoice (item) {
        this.itemDetails = {}
        this.invoiceDialog = true
        this.itemDetails = item
        console.log('this.itemDetails', this.itemDetails)
      },
      onProgressPdf: function (data) {
        this.progressNumber = data
        if (data === 100) {
          setTimeout(() => {
            this.showProgress = false
          }, 1000)
        }
      },
      generateReport: async function (item) {
        this.progressNumber = 0
        this.pdfDataLoading = true
        this.showProgress = true
        this.progressNumber = 40
        this.progressNumber = 40
        this.itemDetails = item
        if (this.itemDetails) {
          this.progressNumber = 20
          await this.$refs.html2Pdf.generatePdf()
          console.log('this.itemDetails', this.itemDetails)
          this.successMessage = 'تمت تحميل الملف بنجاح'
          this.successSnackbar = true
        } else {
          this.errorMessage = 'يوجد مشكلة في تحميل الملف برجاء المحاولة مرة اخري'
          this.errorSnackbar = true
        }
      },
      setFilter (value) {
        this.filter = value
        this.fetchAllItems()
      },
      async fetchAllItems () {
        this.dataLoading = true
        const { page, itemsPerPage } = this.options
        const pageNumber = page - 1
        const list = await salesService.getAllItems(itemsPerPage, page, pageNumber, this.filter)
        console.log('list', list)
        this.list = list.data
        this.total = list.total
        this.path = list.path
        this.dataLoading = false
      },
      async exportExel () {
        this.loading = true
        const listData = await salesService.exportItems()
        console.log('listData', listData)
        const blob = new Blob([listData.data], { type: listData.headers['content-type'] })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const disposition = listData.headers['content-disposition']
        let filename = 'downloadedFile'
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        // Set the filename attribute and trigger download
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        // Cleanup
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
      //   import('@/vendor/Export2Excel').then(excel => {
      //     console.log('listData.data', listData.data)
      //   const tHeader = this.header
      //   const list = listData.data.map(item => {
      //     return {
      //       code: item.product.label,
      //       branch: item.receipts.branch.name,
      //       receipt_date: item.receipts.receipt_date,
      //       metal: item.product.metal.name,
      //       receipts: item.receipts.receipt_number,
      //       total_egp: item.receipts.total_egp,
      //       paid_egp: item.receipts.paid_egp,
      //       remainingAmount: item.receipts.total_egp - item.receipts.paid_egp,
      //       employee: item.receipts.employee.name,

      //       }
      //   })
      //   const data = this.formatJson(list)
      //   excel.export_json_to_excel({
      //     header: tHeader,
      //     data,
      //     filename: this.filename,
      //     autoWidth: this.autoWidth,
      //     bookType: this.bookType,
      //   })
        // this.loading = false
      // })
      },
      formatJson (jsonData) {
        return jsonData.map(v => {
          return Object.values(v)
        })
      },
    },
  }
</script>
<style>
a{
  text-decoration: none;
}
</style>
